import * as React from 'react';

import { IconBase } from './utils';

export const EnvelopeIcon: IconBase = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg width="18" height="15" viewBox="0 0 21 17" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 0.0634766H2.5C1.4 0.0634766 0.51 0.963477 0.51 2.06348L0.5 14.0635C0.5 15.1635 1.4 16.0635 2.5 16.0635H18.5C19.6 16.0635 20.5 15.1635 20.5 14.0635V2.06348C20.5 0.963477 19.6 0.0634766 18.5 0.0634766ZM2.5 4.06348L10.5 9.06348L18.5 4.06348V14.0635H2.5V4.06348ZM2.5 2.06348L10.5 7.06348L18.5 2.06348H2.5Z"
        fill={color || 'white'}
      />
    </svg>
  );
};
