import { navigate } from '@reach/router';
import {
  addBreadcrumb,
  captureException,
  captureMessage,
  Severity,
} from '@sentry/browser';
import * as React from 'react';

import { urlWithUtmInfo } from '../services/analytics';
import { Button } from './button';
import { isFarEnoughInFutureForRescheduling } from './calendar/slot-filters';
import { useAppointment } from './calendar/use-appointment';
import { prettyDayDateMonth, prettyTimeSlot } from './calendar/utils';
import { CannotBeRescheduled } from './cannot-be-rescheduled';
import { TickIcon } from './icons/TickIcon';
import { Spinner } from './spinner';

const AppointmentTimesNotFound = () => {
  React.useEffect(() => {
    captureMessage('Appointment times not found', Severity.Error);
  }, []);

  return (
    <section className="section message">
      <div className="container--small center">
        <h1 className="heading1">Something went wrong</h1>
        <p className="faded-text">We couldn&apos;t find your appointment.</p>
      </div>
    </section>
  );
};

type ConfirmationProps = { appointmentId: string | undefined };

export const Confirmation: React.FC<ConfirmationProps> = ({
  appointmentId,
}) => {
  const [appointment, isLoading, error] = useAppointment(appointmentId);

  React.useEffect(() => {
    if (error) {
      addBreadcrumb({
        category: 'booking-confirmation',
        message: 'Failed to load appointment',
        data: { error },
      });
      captureException(error);
    }
  }, [appointment, isLoading, error]);

  const hasStartedLoading = React.useRef(false);

  if (isLoading) {
    addBreadcrumb({
      category: 'booking-confirmation',
      message: 'Loading appointment',
    });
    hasStartedLoading.current = true;
    return <Spinner />;
  }

  // Prevent flash-of-error before loading by showing spinner until it starts loading
  if (!hasStartedLoading.current) {
    addBreadcrumb({
      category: 'booking-confirmation',
      message: 'Before loading appointment',
    });
    return <Spinner />;
  }

  if (
    !appointment?.fulfillmentWindow.startDateTime ||
    !appointment?.fulfillmentWindow.endDateTime
  ) {
    return <AppointmentTimesNotFound />;
  }

  const { startDateTime, endDateTime } = appointment.fulfillmentWindow;
  const canBeRescheduled = isFarEnoughInFutureForRescheduling(
    new Date(startDateTime),
  );

  if (!canBeRescheduled) return <CannotBeRescheduled />;

  const appointmentDate = prettyDayDateMonth(startDateTime);
  const appointmentTime = prettyTimeSlot({ startDateTime, endDateTime });

  return (
    <div className="confirmation">
      <div className="confirmation__heading">
        <TickIcon />
        <h1 className="confirmation__heading__text success">
          Your service has been booked
        </h1>
      </div>
      <p>
        We’ve sent you a confirmation email. You can now close this&nbsp;window.
      </p>

      <h2 className="heading2">Appointment Time</h2>
      <p>
        {appointmentDate}, {appointmentTime} (UK Time)
      </p>
      <Button
        variant="link"
        onClick={() => navigate(urlWithUtmInfo('calendar'), { replace: true })}
      >
        Reschedule
      </Button>
    </div>
  );
};
