import * as React from 'react';

import envelopeImage from '../assets/envelope.png';
import { useGlobalState } from '../utils/global-state';
import { EnvelopeIcon } from './icons/EnvelopeIcon';

export const CannotBeRescheduled: React.FC = () => {
  const [brand] = useGlobalState('brand');

  return (
    <div className="cannot-reschedule">
      <img src={envelopeImage} alt="Envelope Icon" />
      <h2>Your booking is less than 48 hours away</h2>
      <p className="help-text">
        Your booking is within 48 hours. As a result please contact us to
        rearrange your booking.{' '}
      </p>
      <a
        className="support"
        href={`mailto:${brand.supportEmail}`}
        target="_blank"
        rel="noreferrer"
      >
        <EnvelopeIcon />
        {brand.supportEmail}
      </a>
    </div>
  );
};
