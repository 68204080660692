import React, { useEffect } from 'react';

import { trackPageView } from '../services/analytics';

type SomethingWentWrongProps = {
  appointmentId?: string;
  text: string;
};

export const SomethingWentWrong = ({
  appointmentId,
  text,
}: SomethingWentWrongProps) => {
  useEffect(() => {
    trackPageView('Portal-Something went wrong shown', {
      appointmentId,
    });
  }, [appointmentId]);

  return (
    <section className="section error">
      <div className="container--small center">
        <h2 className="heading3">Something went wrong</h2>
        <p>{text}</p>
      </div>
    </section>
  );
};
