import { navigate } from '@reach/router';
import { addBreadcrumb, captureException } from '@sentry/browser';
import React from 'react';

import { urlWithUtmInfo } from '../services/analytics';
import { AppointmentResponse } from '../services/kantan-client';
import { useAppointment } from './calendar/use-appointment';
import { SomethingWentWrong } from './something-went-wrong';
import { SpinnerPage } from './spinner-page';

const useCaptureError = (error: Error | undefined) => {
  React.useEffect(() => {
    if (error) {
      captureException(error);
    }
  }, [error]);
};

const useCheckExistingAppointmentWithRedirect = (
  appointmentId: string | undefined,
): [AppointmentResponse | undefined, boolean, Error | undefined] => {
  const [appointment, isLoading, appointmentError] = useAppointment(
    appointmentId,
  );

  useCaptureError(appointmentError);

  React.useEffect(() => {
    if (!isLoading && appointment) {
      if (appointment.fulfillmentWindow.startDateTime) {
        addBreadcrumb({
          category: 'entry',
          message: 'Appointment already booked, redirect to confirmation page',
        });
        navigate(urlWithUtmInfo('confirmation'), { replace: true });
      } else {
        addBreadcrumb({
          category: 'entry',
          message: 'Appointment not booked yet, redirect to booking calendar',
        });
        navigate(urlWithUtmInfo('calendar'), { replace: true });
      }
    }
  }, [appointment, isLoading]);

  return [appointment, isLoading, appointmentError];
};

type BookingEntryProps = { appointmentId: string | undefined };

export const BookingEntry: React.FC<BookingEntryProps> = ({
  appointmentId,
}) => {
  const [, isLoading, error] = useCheckExistingAppointmentWithRedirect(
    appointmentId,
  );

  if (!isLoading && error) {
    return (
      <SomethingWentWrong
        appointmentId={appointmentId}
        text="We couldn't retrieve your details."
      />
    );
  }

  return (
    <div className="section">
      <SpinnerPage />
    </div>
  );
};
